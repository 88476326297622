<div class="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
    <div class="divimage dzsparallaxer--target"
        style="width: 101%; height: 130%; background-image: url(assets/img/bg-img/bg-2.jpg)"></div>
    <!-- breadcrumb content -->
    <div class="breadcumb-content">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12">
                    <nav aria-label="breadcrumb" class="breadcumb--con text-center">
                        <h2 class="w-text title wow fadeInUp" data-wow-delay="0.2s">Facilities</h2>
                        <ol class="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Failities</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row align-items-center" style="padding-top: 30px;">
    <div class="col-12 col-lg-3 col-md-12 ">

        <div class="col-xs-12">
            <div class="article special box-shadow">
                <img style="height: 200px !important;" src="assets/img/icons/ICP-OES.jpg" class="mb-10" alt="">
                <h3 class="article__title">ICP OES</h3>
            </div>
        </div>
        <div class="col-xs-12">
            <div class="article special box-shadow">
                <img style="height: 200px !important;" src="assets/img/icons/ics-6000.jpg" class="mb-10" alt="">
                <h3 class="article__title">ICS 6000</h3>
            </div>
        </div>

    </div>

    <div class="col-12 col-lg-3 col-md-12">

        <div class="col-xs-12">
            <div class="article special box-shadow mts-50">
                <img style="height: 200px !important;" src="assets/img/icons/FAT-EXTRACTION.jpg" class="mb-10"
                    alt="">
                <h3 class="article__title">Fat Extraction</h3>
            </div>
        </div>
        <div class="col-xs-12">
            <div class="article special box-shadow">
                <img style="height: 200px !important;" src="assets/img/icons/FTIR.jpg" class="mb-10" alt="">
                <h3 class="article__title">FTIR</h3>
            </div>
        </div>

    </div>

    <div class="col-12 col-lg-3 col-md-12 ">

        <div class="col-xs-12">
            <div class="article special box-shadow">
                <img style="height: 200px !important;" src="assets/img/icons/GC-HEAD SPACE.jpg" class="mb-10"
                    alt="">
                <h3 class="article__title">GC-HEAD SPACE</h3>
            </div>
        </div>
        <div class="col-xs-12">
            <div class="article special box-shadow">
                <img style="height: 200px !important;" src="assets/img/icons/lcmsms.webp" class="mb-10" alt="">
                <h3 class="article__title">lcmsms</h3>
            </div>
        </div>

    </div>

    <div class="col-12 col-lg-3 col-md-12">

        <div class="col-xs-12">
            <div class="article special box-shadow mts-50">
                <img style="height: 200px !important;" src="assets/img/icons/TSQ-8000 gcmsms.jpg" class="mb-10"
                    alt="">
                <h3 class="article__title">TSQ-8000 gcmsms</h3>
            </div>
        </div>
        <div class="col-xs-12">
            <div class="article special box-shadow">
                <img style="height: 200px !important;" src="assets/img/icons/vanquish-core-HPLC.jpg"
                    class="mb-10" alt="">
                <h3 class="article__title">Vanquish Core HPLC</h3>
            </div>
        </div>

    </div>

    <div class="col-12 col-lg-6 offset-lg-0 col-md-10 offset-md-1" hidden>
        <div class="who-we-contant mt-s">
            <div class="promo-section">
                <h3 class="special-head ">Learn More About Us!</h3>
            </div>
            <h4 class="bl-text fadeInUp" data-wow-delay="0.3s">We Are The Trusted Experts We Keep Things
                Simple</h4>
            <p class="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Duis at dictum risus, non suscipit arcu. Quisque aliquam posuere tortor, sit amet
                convallis nunc scelerisque in.</p>
            <p class="fadeInUp" data-wow-delay="0.5s">Lorem ipsum dolor sit amet, consectetur adipisicing
                elit. Nemo quo laboriosam, dolorum ducimus aliquam consequuntur!</p>
            <a class="btn dream-btn green-btn mt-30 fadeInUp" data-wow-delay="0.6s">Read More</a>
        </div>
    </div>

</div>