import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from 'src/app/models/contact/contact.model';
import { Data } from 'src/app/components/contact/contact.component';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private _http: HttpClient) { }

  /**
   * This function loads all services
   */
  public getAllServices() {
    return this._http.get('/assets/json/services.json');
  }

  

  /**
   * This function loads all services
   */
  public getServiceDetails(serviceName: string) {
    switch (serviceName) {
      case 'softlines':
        default:
        return this._http.get('assets/json/softlines-service.json');
      case 'pharmaceuticals':
        return this._http.get('assets/json/pharmaceuticals-service.json');
      case 'water':
        return this._http.get('assets/json/water-service.json');
      case 'microbiology':
        return this._http.get('assets/json/microbiology-service.json');
      case 'environmental':
        return this._http.get('assets/json/environmental-service.json');
      case 'food':
        return this._http.get('assets/json/food-service.json');
    }

  }

  public createContact(payload: Array<Data>) {
    return this._http.post('https://demo.matricsdigital.com/api/generic-mysql/create-by-name/barc-create-contact', payload, { headers: { "content-type": "application/json" } });
  }
}
