import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare function handlePreloader(): any;
declare function showAccordion(): any;
declare function slider(): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit(): void {
    handlePreloader();
    showAccordion();
    slider();
  }

}
