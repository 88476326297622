<div class="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
    <div class="divimage dzsparallaxer--target"
        style="width: 101%; height: 130%; background-image: url(assets/img/bg-img/bg-2.jpg)"></div>
    <!-- breadcrumb content -->
    <div class="breadcumb-content">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12">
                    <nav aria-label="breadcrumb" class="breadcumb--con text-center">
                        <h2 class="w-text title wow fadeInUp" data-wow-delay="0.2s">Blog Details</h2>
                        <ol class="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Blog</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Blog Details</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area section-padding-150-0">
    <div class="container">

        <div class="row">
            <div class="col-12 col-md-9">
                <div class="single-blog-area wow fadeInUp" data-wow-delay="0.2s">
                    <!-- Post Thumbnail -->
                    <div class="blog_thumbnail">
                        <img src="assets/img/blog-img/2.jpg" alt="">
                    </div>
                    <!-- Post Content -->
                    <div class="blog-content">

                        <div class="post-meta">
                            <p>By <a href="#" class="post-author">Nazrul</a> <a href="#">Apr 10, 2019</a> <a
                                    href="#" class="post-comments">7 comments</a></p>
                        </div>
                        <h4 class="post-title">How to become a successful businessman.</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad voluptas tempora inventore
                            minima consectetur fugit deserunt neque modi, culpa atque fugiat unde amet quidem, at
                            corrupti dignissimos repudiandae, totam sapiente.</p>
                        <p hidden>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita fugit eum doloribus
                            iste voluptatum perferendis dolorem qui! Vel magnam provident, laudantium voluptates
                            sit, sint iure sunt harum distinctio ab, aliquam est voluptas minus id explicabo illum
                            odio? Officia omnis minima similique, eveniet eos. Rem itaque laboriosam quas est omnis,
                            mollitia possimus eveniet. Veritatis nihil, aliquid quisquam, laborum quis ut rem
                            repellendus animi repellat, alias suscipit nostrum incidunt. Quis, quas.</p>
                        <p hidden>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque necessitatibus laborum
                            eveniet eaque laboriosam in officia reprehenderit quas itaque numquam sit ipsum libero
                            consequuntur quia non quod esse rem corporis vero, consequatur nam, labore. Assumenda
                            dolores possimus voluptatem deleniti, ipsa doloribus voluptatibus adipisci, ex
                            voluptatum doloremque perspiciatis sunt. A earum natus quisquam deserunt nulla saepe,
                            cumque, distinctio fuga animi dignissimos. Fuga, autem. Rem eveniet animi repellendus
                            voluptatibus officiis sit cum doloremque dolorem labore maxime, ipsa cumque deleniti
                            distinctio tempora vitae eos debitis. Voluptatum enim in ab facilis sapiente, quidem
                            numquam, maxime ex veritatis assumenda, accusamus similique.</p>
                        <blockquote>
                            <h5 hidden>"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident, vero excepturi
                                repellendus quae ipsum. Cum totam cumque quis eveniet saepe nisi.</h5>
                            <span>- Nazrul, CEO OUR COMPANY</span>
                        </blockquote>
                        <p hidden>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente quasi earum dolores
                            sed pariatur molestias, aliquid eligendi, non consequatur quisquam repudiandae debitis
                            numquam? Eligendi nostrum laborum, labore minima quaerat at eius tempora quos numquam
                            dolores cum cupiditate voluptates necessitatibus, quis esse, ea. Tempora excepturi quia
                            magnam itaque consequuntur iusto, dolore, nulla omnis, doloremque magni, amet pariatur!
                            Maxime libero temporibus, soluta, qui veniam laudantium cupiditate corporis similique!
                            Consectetur rerum, fuga! Quia nam fuga magnam quas aliquam in, saepe beatae. Culpa
                            soluta, rerum id ex voluptate veniam maxime odit ad modi odio, molestiae est deserunt
                            assumenda voluptatibus fuga accusamus placeat a quam!</p>
                        <p hidden>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur commodi expedita
                            accusantium sapiente culpa rem vel voluptas soluta error adipisci doloremque corporis
                            inventore molestias architecto, iusto tempora rerum. Molestias tempora possimus ea,
                            cumque culpa corrupti dolor harum, alias, voluptatem at ex laborum excepturi iusto
                            asperiores optio suscipit consectetur quis quos dolorum deleniti dicta aut similique,
                            eius fuga. Rem natus deserunt, harum? Quasi, fuga, culpa.</p>
                    </div>
                </div>

                <!-- Comment Area Start -->
                <div class="comment_area clearfix mt-70 wow fadeInUp" data-wow-delay="0.4s">
                    <h5 class="title">Comments</h5>

                    <ol>
                        <!-- Single Comment Area -->
                        <li class="single_comment_area">
                            <!-- Comment Content -->
                            <div class="comment-content d-flex">
                                <!-- Comment Author -->
                                <div class="comment-author">
                                    <img src="assets/img/test-img/1.jpg" alt="author">
                                </div>
                                <!-- Comment Meta -->
                                <div class="comment-meta">
                                    <p>Efficitur lorem sed tempor. Integer aliquet tempor cursus. Nullam vestibulum
                                        convallis risus vel condimentum. Nullam auctor lorem in libero luctus, vel
                                        volutpat quam tincidunt.</p>
                                    <a href="#" class="post-date">April 14</a>
                                    <p><a href="#" class="post-author">Lim Sarah</a></p>
                                    <a href="#" class="comment-reply">Reply</a>
                                </div>
                            </div>
                            <ol class="children">
                                <li class="single_comment_area">
                                    <!-- Comment Content -->
                                    <div class="comment-content d-flex">
                                        <!-- Comment Author -->
                                        <div class="comment-author">
                                            <img src="assets/img/test-img/2.jpg" alt="author">
                                        </div>
                                        <!-- Comment Meta -->
                                        <div class="comment-meta">
                                            <p>Efficitur lorem sed tempor. Integer aliquet tempor cursus. Nullam
                                                vestibulum convallis risus vel condimentum. Nullam auctor lorem in
                                                libero luctus, vel volutpat quam tincidunt.</p>
                                            <a href="#" class="post-date">April 14</a>
                                            <p><a href="#" class="post-author">Lim Sarah</a></p>
                                            <a href="#" class="comment-reply">Reply</a>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </li>

                        <!-- Single Comment Area -->
                        <li class="single_comment_area">
                            <!-- Comment Content -->
                            <div class="comment-content d-flex">
                                <!-- Comment Author -->
                                <div class="comment-author">
                                    <img src="assets/img/test-img/3.jpg" alt="author">
                                </div>
                                <!-- Comment Meta -->
                                <div class="comment-meta">
                                    <p>Efficitur lorem sed tempor. Integer aliquet tempor cursus. Nullam vestibulum
                                        convallis risus vel condimentum. Nullam auctor lorem in libero luctus, vel
                                        volutpat quam tincidunt.</p>
                                    <a href="#" class="post-date">April 14</a>
                                    <p><a href="#" class="post-author">Lim Sarah</a></p>
                                    <a href="#" class="comment-reply">Reply</a>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>

                <div class="post-a-comment-area mt-100 wow fadeInUp" data-wow-delay="0.6s">
                    <h5>Leave a reply</h5>
                    <!-- Reply Form -->
                    <form action="#" method="post">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="group">
                                    <input type="text" name="name" id="name" required>
                                    <span class="bar"></span>
                                    <label>Name</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="group">
                                    <input type="text" name="email" id="email" required>
                                    <span class="bar"></span>
                                    <label>Email</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="group">
                                    <input type="text" name="subject" id="subject" required>
                                    <span class="bar"></span>
                                    <label>Subject</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="group">
                                    <textarea name="message" id="message" required></textarea>
                                    <span class="bar"></span>
                                    <label>Comment</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn dream-btn">Reply</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-12 col-md-3">
                <div class="sidebar-area">

                    <!-- Search Widget -->
                    <div class="search-widget-area mb-50 wow fadeInUp" data-wow-delay="0.2s">
                        <form action="#" method="post">
                            <input type="search" name="search" id="search" placeholder="Search...">
                            <button type="submit" class="btn"><i class="fa fa-search"></i></button>
                        </form>
                    </div>

                    <!-- Don't Miss Widget -->
                    <div class="donnot-miss-widget mb-50">
                        <div class="widget-title wow fadeInUp" data-wow-delay="0.3s">
                            <h5>Don't miss</h5>
                        </div>
                        <!-- Single Don't Miss Post -->
                        <div class="single-dont-miss-post mb-30 wow fadeInUp" data-wow-delay="0.4s">
                            <div class="dont-miss-post-thumb">
                                <img src="assets/img/blog-img/1.jpg" alt="">
                            </div>
                            <div class="dont-miss-post-content">
                                <a href="#">EU council reunites</a>
                                <span>Nov 29, 2020</span>
                            </div>
                        </div>
                        <!-- Single Don't Miss Post -->
                        <div class="single-dont-miss-post mb-30 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="dont-miss-post-thumb">
                                <img src="assets/img/blog-img/2.jpg" alt="">
                            </div>
                            <div class="dont-miss-post-content">
                                <a href="#">A new way to travel the world</a>
                                <span>March 29, 2020</span>
                            </div>
                        </div>
                        <!-- Single Don't Miss Post -->
                        <div class="single-dont-miss-post wow fadeInUp" data-wow-delay="0.6s">
                            <div class="dont-miss-post-thumb">
                                <img src="assets/img/blog-img/3.jpg" alt="">
                            </div>
                            <div class="dont-miss-post-content">
                                <a href="#">Why choose a bank?</a>
                                <span>March 29, 2020</span>
                            </div>
                        </div>
                    </div>

                    <!-- Advert Widget -->
                    <div class="advert-widget mb-50">
                        <div class="widget-title wow fadeInUp" data-wow-delay="0.7s">
                            <h5>Sponsored Add</h5>
                        </div>
                        <div class="advert-thumb mb-30 wow fadeInUp" data-wow-delay="0.8s">
                            <a href="#"><img src="assets/img/services/serv6.jpg" alt=""></a>
                        </div>
                    </div>

                    <!-- Subscribe Widget -->
                    <div class="subscribe-widget mb-50">
                        <div class="widget-title wow fadeInUp" data-wow-delay="0.9s">
                            <h5>subscribe</h5>
                        </div>
                        <div class="subscribe-form wow fadeInUp" data-wow-delay="1s">
                            <form action="#">
                                <input type="email" name="email" id="subs_email" placeholder="Your Email">
                                <button type="submit" class="btn dream-btn">subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>