import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../services/app/app.service';

declare function handlePreloader(): any;

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {

  public serviceName: any = '';
  public serviceDetails: any;
  public selectedServices: any = new Object();

  constructor(
    private _appService: AppService,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    handlePreloader();

    this._activatedRoute.paramMap.subscribe(params => {
      this.serviceName = params.get("serviceName");
      this._appService.getServiceDetails(this.serviceName ? this.serviceName : '').subscribe((responseData) => {
        this.serviceDetails = responseData;
      });
    });
  }

}
