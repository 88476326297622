<div class="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
    <div class="divimage dzsparallaxer--target"
        style="width: 101%; height: 130%; background-image: url(assets/img/bg-img/bg-2.jpg)"></div>
    <!-- breadcrumb content -->
    <div class="breadcumb-content">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12">
                    <nav aria-label="breadcrumb" class="breadcumb--con text-center">
                        <h2 class="w-text title wow fadeInUp" data-wow-delay="0.2s">Services</h2>
                        <ol class="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Services</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <section class="our_services_area section-padding-100-0 relative hex-pat-1" id="services">
        <div class="container">

            <div class="section-heading text-center">
                <span>Our Services</span>
                <h2 class="d-blue bold fadeInUp" data-wow-delay="0.3s">Our Core Services</h2>
                <p class="fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Sed
                    quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
            </div>

            <div class="row">

                <div class="col-12 col-md-6 col-lg-4">
                    <a (click)="onServiceClick('food')">

                        <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.4s">

                            <div class="service_img">
                                <img src="assets/img/services/Food.png" alt="">
                            </div>
                            <div class="serv_icon">
                                <img src="assets/img/icons/s4.png" alt="">
                            </div>
                            <div class="service-content">
                                <h6 class="d-blue bold">Food</h6>
                                <p>The quality of food plays a crucial role which is directly concerned with every
                                    individual. </p>
                                <p hidden>The food quality gets affected by the internal factors like microbial,
                                    chemical, physical and external factors like shape, taste, texture, colour, size.
                                </p>
                                <p hidden> which must be appropriate to get appreciated in terms of health safety
                                    issues.</p>
                                <p hidden>BARC is fully furnished with all the essential equipment’s which are required
                                    for complete testing of broad range of food products (raw and processed
                                    both).We also provide services required for new food products in research and
                                    development fields.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <a (click)="onServiceClick('pharmaceuticals')">
                        <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.4s">

                            <div class="service_img">
                                <img src="assets/img/services/Pharmaceuticals.png" alt="">
                            </div>
                            <div class="serv_icon">
                                <img src="assets/img/icons/s6.png" alt="">
                            </div>
                            <div class="service-content">
                                <h6 class="d-blue bold">Pharmaceuticals</h6>
                                <p>BARC is well-versed in the complexities of modern pharmaceutical laboratory
                                    testing. </p>
                                <p hidden>It is one of the most demanding fields of analytical chemistry,
                                    occupying the intersection between biochemical, synthetic, and analytical
                                    chemistry.</p>
                                <p hidden> WE provide unmatched expertise in the field of pharmaceutical lab
                                    testing. Our analysts are regularly tasked with detecting, quantifying, and
                                    identifying contaminants in dosage form pharmaceuticals.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <a (click)="onServiceClick('environmental')">
                        <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.2s">
                            <div class="service_img">
                                <img src="assets/img/services/Environmental.png" alt="">
                            </div>
                            <div class="serv_icon">
                                <img src="assets/img/icons/s2.png" alt="">
                            </div>
                            <div class="service-content">
                                <h6 class="d-blue bold">Environmental</h6>
                                <p>With the continuous decrease in quality of environment, it has become
                                    essential to keep on monitoring.</p>
                                <p hidden> with various
                                    regulations set by the regulatory bodies.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <a (click)="onServiceClick('water')">
                        <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.2s">
                            <div class="service_img">
                                <img src="assets/img/services/Water.png" alt="">
                            </div>
                            <div class="serv_icon">
                                <img src="assets/img/icons/s1.png" alt="">
                            </div>
                            <div class="service-content">
                                <h6 class="d-blue bold">Water</h6>
                                <p>"Water is a synonym for ‘life’ as no life can
                                    exist on earth without it."</p>
                                <p>Water is a synonym for ‘life’...</p>
                                <p hidden> as no life can
                                    exist on earth without it. BARC understands the importance of purity of water for
                                    human consumption and industrial works.</p>
                                <p hidden> We offer competitive facilities for testing of drinking water and waste
                                    water.</p>
                                <p hidden>We own a well-equipped laboratory which possess the most advanced and
                                    modernized instruments required for carrying out a complete analysis for water
                                    samples used in batteries , petroleum refineries, fermentation industries , food
                                    processing industries , cooling towers etc.</p>
                                <p hidden> The test protocols are strictly in accordance with the specified standards
                                    mention by the concerned authorities.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <a (click)="onServiceClick('microbiology')">
                        <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.2s">
                            <div class="service_img">
                                <img src="assets/img/services/Microbiology.png" alt="">
                            </div>
                            <div class="serv_icon">
                                <img src="assets/img/icons/s3.png" alt="">
                            </div>
                            <div class="service-content">
                                <h6 class="d-blue bold">Microbiology</h6>
                                <p>BARC has been providing world class services to various industries for
                                    Microbiology testing for past few years.</p>
                                <p hidden>We ensure that the products are
                                    safe and are manufactured as per the requirements of specific
                                    standards.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <a (click)="onServiceClick('softline')">
                        <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.4s">
                            <div class="service_img">
                                <img src="assets/img/services/Softlines.png" alt="">
                            </div>
                            <div class="serv_icon">
                                <img src="assets/img/icons/s5.png" alt="">
                            </div>
                            <div class="service-content">
                                <h6 class="d-blue bold">Softlines</h6>
                                <p>Before a soft line material or a product is put into market for consumer
                                    application...</p>
                                <p hidden> it needs to be checked on different stages of its production.
                                    and
                                    practice under various conditions.</p>
                                <p hidden>With reference to every growing consumer demands and complying with the
                                    stringent government rules and regulations, BARC helps your products satisfy
                                    the entire requirement and helps maintain their quality and performance and
                                    minimize the risk associated with the material and secure the interest of
                                    consumer and manufacturer as well.</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>

    <section class="creative-facts section-before section-padding-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="c-facts-box">
                        <div class="who-we-contant">
                            <div class="promo-section">
                                <h3 class="special-head">Our Numbers Are Talking</h3>
                            </div>
                            <h4 class="wow fadeInUp bold" data-wow-delay="0.3s">We Care Too Much About Our Customers
                                Satisfication</h4>
                            <p class="wow fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Duis at dictum risus, non suscipit arcu. Quisque aliquam posuere
                                tortor, sit amet convallis nunc scelerisque in.</p>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6">
                                    <!-- Single Cool Fact -->
                                    <div class="single_cool_fact text-center wow fadeInUp" data-wow-delay="0.2s">
                                        <div class="cool_fact_icon">
                                            <i class="ti-user"></i>
                                        </div>
                                        <!-- Single Cool Detail -->
                                        <div class="cool_fact_detail">
                                            <h3><span class="counter">3215</span>+</h3>
                                            <h2>Happy Clients</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6">
                                    <!-- Single Cool Fact -->
                                    <div class="single_cool_fact text-center wow fadeInUp" data-wow-delay="0.5s">
                                        <div class="cool_fact_icon">
                                            <i class="ti-cup"></i>
                                        </div>
                                        <!-- Single Cool Detail -->
                                        <div class="cool_fact_detail">
                                            <h3><span class="counter">230</span>+</h3>
                                            <h2>Awards Won</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="about-us-area section-padding-70-70">

        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-3 col-md-12 ">

                    <div class="col-xs-12">
                        <div class="article special box-shadow">
                            <img src="assets/img/icons/s1.png" class="mb-10" alt="">
                            <h3 class="article__title">Chemical Research</h3>
                            <p>Lorem ipsum dolor sit amet, conse ctetur adipi sicing elit</p>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <div class="article special box-shadow">
                            <img src="assets/img/icons/s2.png" class="mb-10" alt="">
                            <h3 class="article__title">Advanced Microscopy</h3>
                            <p>Lorem ipsum dolor sit amet, conse ctetur adipi sicing elit</p>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-lg-3 col-md-12">

                    <div class="col-xs-12">
                        <div class="article special box-shadow mts-50">
                            <img src="assets/img/icons/s3.png" class="mb-10" alt="">
                            <h3 class="article__title">Molecular Biology</h3>
                            <p>Lorem ipsum dolor sit amet, conse ctetur adipi sicing elit</p>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <div class="article special box-shadow">
                            <img src="assets/img/icons/s4.png" class="mb-10" alt="">
                            <h3 class="article__title">Pathology Testing</h3>
                            <p>Lorem ipsum dolor sit amet, conse ctetur adipi sicing elit</p>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-lg-6 offset-lg-0 col-md-10 offset-md-1">
                    <div class="who-we-contant mt-s">
                        <div class="promo-section">
                            <h3 class="special-head ">Learn More About Us!</h3>
                        </div>
                        <h4 class="bl-text fadeInUp" data-wow-delay="0.3s">We Are The Trusted Experts We Keep Things
                            Simple</h4>
                        <p class="fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur
                            adipiscing
                            elit. Duis at dictum risus, non suscipit arcu. Quisque aliquam posuere tortor, sit amet
                            convallis nunc scelerisque in.</p>
                        <p class="fadeInUp" data-wow-delay="0.5s" hidden>Lorem ipsum dolor sit amet, consectetur
                            adipisicing
                            elit. Nemo quo laboriosam, dolorum ducimus aliquam consequuntur!</p>
                        <a class="btn dream-btn green-btn mt-30 fadeInUp" data-wow-delay="0.6s" hidden>Read More</a>
                    </div>
                </div>

            </div>
        </div>
    </section>
</div>