<!-- ##### Header Area Start ##### -->
<header class="header-area fadeInDown" data-wow-delay="0.2s">
  <div class="classy-nav-container breakpoint-off dark">
    <div class="container">
      <!-- Classy Menu -->
      <nav class="classy-navbar justify-content-between" id="dreamNav">

        <!-- Logo -->
        <a class="nav-brand" href="index.html"><img src="assets/img/core-img/logo.png" width="300px" alt="logo"></a>

        <!-- Navbar Toggler -->
        <div class="classy-navbar-toggler">
          <span class="navbarToggler"><span></span><span></span><span></span></span>
        </div>

        <!-- Menu -->
        <div class="classy-menu">

          <!-- close btn -->
          <div class="classycloseIcon">
            <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
          </div>

          <!-- Nav Start -->
          <div class="classynav">
            <ul id="nav">
              <!-- <li><a href="index.html">Home</a></li> -->
              <li>
                <a [routerLink]="['home']" [class.active]="!activePage || activePage === 'home'" routerLinkActive="current">Home</a>
              </li>
              <li><a [routerLink]="['about-us']" [class.active]="activePage === 'about-us'" routerLinkActive="current">About Us</a></li>
              <li><a [routerLink]="['services']" [class.active]="activePage === 'services'" routerLinkActive="current">Services</a></li>
              <li><a [routerLink]="['faq']" [class.active]="activePage === 'faq'" routerLinkActive="current">FAQ</a></li>
              <li><a [routerLink]="['facilities']" [class.active]="activePage === 'facilities'" routerLinkActive="current">Facilities</a></li>
              <li><a *ngIf="false" [routerLink]="['blog']" routerLinkActive="current">Blog</a> </li>
              <li><a [routerLink]="['contact']" [class.active]="activePage === 'contact'" routerLinkActive="current">Contact</a></li>
            </ul>
          </div>
          <!-- Nav End -->
        </div>
      </nav>
    </div>
  </div>
</header>
<!-- ##### Header Area End ##### -->

<!-- Preloader -->
<div id="preloader">
  <div class="preload-content">
    <div id="dream-load"></div>
  </div>
</div>

<!-- Pages here -->
<router-outlet> </router-outlet>

<div class="footer-bg">
  <!-- ##### Testimonial Area Start ##### -->
  <section class="clients_testimonials_area bg-img section-padding-100-0" id="test" hidden>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-heading text-center" hidden>
            <span>What Clients Say</span>
            <h2 class="d-blue bold bl-text wow fadeInUp" data-wow-delay="0.3s">Loved By Our Clients</h2>
            <p class="wow fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="cotainer-fluid">
      <div class="row justify-content-center wow fadeInUp" data-wow-delay="0.4s">
        <div class="col-12 col-md-10 col-xs-10 offset-xs-1">
          <div class="client_slides owl-carousel">

            <!-- Single Testimonial -->
            <div class="single-testimonial text-center">
              <!-- Testimonial Image -->
              <div class="testimonial_image">
                <img src="assets/img/test-img/1.jpg" alt="">
              </div>
              <!-- Testimonial Feedback Text -->
              <div class="testimonial-description">
                <div class="testimonial_text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis magni,
                    quisquam, accusantium dolores atque, doloribus odit minus maiores sunt
                    mollitia consequatur, soluta quasi.</p>
                </div>

                <!-- Admin Text -->
                <div class="admin_text">
                  <h5>Sunny Khan</h5>
                  <p>Head of Design, Company CEO</p>
                </div>
              </div>
            </div>

            <!-- Single Testimonial -->
            <div class="single-testimonial text-center">
              <!-- Testimonial Image -->
              <div class="testimonial_image">
                <img src="assets/img/test-img/2.jpg" alt="">
              </div>
              <!-- Testimonial Feedback Text  -->
              <div class="testimonial-description">
                <div class="testimonial_text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error nostrum
                    adipisci porro quisquam. Rem, earum, tenetur? Architecto et, earum
                    repudiandae.</p>
                </div>

                <!-- Admin Text -->
                <div class="admin_text">
                  <h5>Ajoy Das</h5>
                  <p>Head of Idea, Company CEO</p>
                </div>
              </div>
            </div>

            <!-- Single Testimonial -->
            <div class="single-testimonial text-center">
              <!-- Testimonial Image -->
              <div class="testimonial_image">
                <img src="assets/img/test-img/3.jpg" alt="">
              </div>
              <!-- Testimonial Feedback Text  -->
              <div class="testimonial-description">
                <div class="testimonial_text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et delectus, nam
                    repudiandae repellat id placeat molestias consequuntur, laudantium dolorem
                    nesciunt sit.</p>
                </div>
                <!-- Admin Text -->
                <div class="admin_text">
                  <h5>Jebin Khan</h5>
                  <p>Head of Marketing, Company CEO</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 
  <!-- ##### Footer Area Start ##### -->
  <footer class="footer-area bg-img">
    <div class="footer-content-area" style="padding-top: 20px;">
      <div class="container">
        <div class="row ">
          <div class="col-lg-4 col-md-6">
            <div class="footer-copywrite-info">
              <div class="copywrite_text fadeInUp" style="margin-left: auto !important; margin-right: auto !important;" data-wow-delay="0.2s">
                <div class="footer-logo">
                  <a href="index.html"><img src="assets/img/core-img/logo.png" alt="logo"> BARC </a>
                </div>
                <p>BARC is a quality oriented, Reliable testing laboratory with state of the art
                   testing equipment ,technology with data integrity. We follow stringent international and
                   national quality protocols to ensure your tests are as accurate as possible.</p>
              </div>
              <div class="footer-social-info fadeInUp" style="margin-left: auto !important; margin-right: auto !important;" data-wow-delay="0.4s">
                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                <a href="#"> <i class="fa fa-twitter" aria-hidden="true"></i></a>
                <a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                <a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                <a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>          
          <div class="col-lg-4 col-md-6">
            <!-- Content Info -->
            <div class="contact_info_area d-sm-flex justify-content-between">
              <div class=" contact_info mt-s text-center fadeInUp" style="margin-left: auto !important; margin-right: auto !important;"  data-wow-delay="0.2s">
                <h5>NAVIGATE</h5>
             
                <a  [routerLink]="['about-us']">
                  <p>ABOUT US</p>
                </a>
                <a  [routerLink]="['services']">
                  <p>SERVICES</p>
                </a>
                <a [routerLink]="['faq']">
                  <p>FAQ</p>
                </a>
                <a [routerLink]="['facilities']">
                  <p>FACILITIES</p>
                </a>
                <a [routerLink]="['contact']">
                  <p>CONTACT</p>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="contact_info_area d-sm-flex justify-content-between">             
              <div class="contact_info mt-s text-center fadeInUp" style="margin-left: auto !important; margin-right: auto !important;">
                <h5>CONTACT US</h5>
                <p>57, Shri Vijayaraja Estate,</p>
                <p>Chokkanahalli, Jakkur Post,</p>
                <p>Bengaluru - 560064</p>
                <p>080-29652966</p>
                <p>enquiry@barcindia.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="auto-container clearfix">
        <div class="copyright text-center">Copyright 2021 &copy; All Rights Reserved. Created By
          <a class="" style="color: blue;" target="_blank" href="https://www.matricsdigital.com/">Matrics Digital</a>
          with love
        </div>
      </div>
    </div>
  </footer>
</div>