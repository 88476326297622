<div class="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
    <div class="divimage dzsparallaxer--target"
        style="width: 101%; height: 130%; background-image: url(assets/img/bg-img/bg-2.jpg)"></div>
    <!-- breadcrumb content -->
    <div class="breadcumb-content">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12">
                    <nav aria-label="breadcrumb" class="breadcumb--con text-center">
                        <h2 class="w-text title wow fadeInUp" data-wow-delay="0.2s">Service Details | {{serviceName | titlecase }}</h2>
                        <ol class="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Service Details</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-us-area section-padding-100 relative">
    <div class="container">
        <div class="row align-items-center">
            <div class="who-we-contant mt-s col-lg-12">
                <div class="promo-section">
                    <h3 class="special-head">Products!</h3>
                </div>
                <div class="list-wrap align-items-center">
                    <div class="row">
                        <span class="col-md-6" *ngFor="let eachProduct of serviceDetails.products">
                            <span class="side-feature-list-item">
                                <img src="assets/img/icons/check.png" class="check-mark-icon" alt="">
                                <div class="col-md-12 foot-c-info">{{eachProduct.name}}</div>
                            </span>
                            <br>
                            <span class="side-feature-list-item" *ngFor="let eachItem of eachProduct.items">
                                <div class="foot-c-info">{{eachItem.name}}</div>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="who-we-contant mt-s" style="padding-top: 40px;">
                <div class="promo-section">
                    <h3 class="special-head ">Services!</h3>
                </div>
                <div class="list-wrap align-items-center">
                    <div class="row">
                        <div class="col-md-6" *ngFor="let eachService of serviceDetails.services">
                            <span class="side-feature-list-item">
                                <img src="assets/img/icons/check.png" class="check-mark-icon" alt="">
                                <div class="foot-c-info">{{eachService.name}}</div>
                            </span>
                            <br>
                            <span class="side-feature-list-item" *ngFor="let eachServiceItem of eachService.items">
                                <div class="foot-c-info">{{eachServiceItem.name}}</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>