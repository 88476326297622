import { Component, OnInit } from '@angular/core';

declare function handlePreloader(): any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  public tabToShow: any = 1;
  constructor() { }

  ngOnInit(): void {
    handlePreloader();
  }

  onToogleClick(num: any) {
    this.tabToShow = num;
  }
}
