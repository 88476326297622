import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare function handlePreloader(): any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit(): void {
    handlePreloader();
  }

  onServiceClick(serviceName: string) {
    this._router.navigateByUrl('/service/' + serviceName);
  }

}
