import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app/app.service';
import { Contact } from '../../models/contact/contact.model';

declare function handlePreloader(): any;
declare function navbar(): any;

export class Data {
  key!: string;
  data: any;
  type!: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public contactModel: Contact;

  constructor(
    private _appService: AppService,
    private _snackBar: MatSnackBar,
    private _toastr: ToastrService
    ) {
    this.contactModel = new Contact();
  }

  ngOnInit(): void {
    handlePreloader();
    navbar();
  }

  public onSendMessage(data: any) {
    let payload = new Array<Data>();

    payload.push({data: this.contactModel.name, key: 'name', type: "STRING"});
    payload.push({data: this.contactModel.email, key: 'email', type: "STRING"});
    payload.push({data: this.contactModel.subject, key: 'subject', type: "STRING"});
    payload.push({data: this.contactModel.message, key: 'message', type: "STRING"});

    if (!this.checkEmptyForm(this.contactModel)) {
      this._appService.createContact(payload).subscribe((response: any) => {
        let message: string;
        if (response && response.affectedRows === 1) {
          message = 'We\'ll get back to you shortly.';
          this._toastr.success(message, 'Thankyou');
        }
        else {
          message = 'Please call us at +91-9835655532.';
          this._toastr.error(message, 'Unable to send a message');
        }
      });
    } else {
      this._toastr.warning('Please enter your message.', 'Error');
    }
  }

  private checkEmptyForm(formObject: Object): boolean {
    let isEmptyForm = true;
    Object.keys(formObject).forEach((eachKey) => {
      if ((<any>formObject)[eachKey] && (<any>formObject)[eachKey] !== '') {
        isEmptyForm = false;
      }
    });
    return isEmptyForm;
  }
}