<!-- ##### Welcome Area Start ##### -->
<div class="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
    <div class="divimage dzsparallaxer--target"
        style="width: 101%; height: 130%; background-image: url(assets/img/bg-img/bg-2.jpg)"></div>
    <!-- breadcrumb content -->
    <div class="breadcumb-content">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12">
                    <nav aria-label="breadcrumb" class="breadcumb--con text-center">
                        <h2 class="w-text title wow fadeInUp" data-wow-delay="0.2s">FAQ Questions</h2>
                        <ol class="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">FAQ</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ##### Welcome Area End ##### -->



<!-- ##### FAQ & Timeline Area Start ##### -->
<div class="faq-timeline-area section-padding-100-85" id="faq">
    <div class="container">
        <div class="section-heading text-center">
            <span>Important questions</span>
            <h2 class="d-blue bold fadeInUp" data-wow-delay="0.3s"> Frequently Questions</h2>
            <p class="fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                <div class="welcome-meter about-sec-wrapper wow fadeInUp" data-wow-delay="0.4s">
                    <img src="assets/img/core-img/about-sec2.jpg" class="about-i" alt="">
                    <div class="article special width-50 box-shadow">
                        <div class="form-head">
                            <div class="form-head-icon"><img src="assets/img/icons/info1.png" alt=""></div>
                            <div class="form-head-info">
                                <h6 class="w-text">9:00 a.m to 6:00 p.m.(CST)</h6>
                                <p class="g-text">Monday through Friday</p>
                            </div>
                        </div>
                        <div class="form-head">
                            <div class="form-head-icon"><img src="assets/img/icons/info2.png" alt=""></div>
                            <div class="form-head-info">
                                <h6 class="w-text">enquiry@barcindia.com</h6>
                                <p class="g-text">Monday through Friday</p>
                            </div>
                        </div>
                        <div class="form-head mb-0">
                            <div class="form-head-icon"><img src="assets/img/icons/info3.png" alt=""></div>
                            <div class="form-head-info">
                                <h6 class="w-text">080-29652966</h6>
                                <p class="g-text">9:00 a.m to 6:00 p.m</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-12">
                <div class="dream-faq-area mt-s ">
                    <dl style="margin-bottom:0">
                        <!-- Single FAQ Area -->
                        <dt class="wave fadeInUp" data-wow-delay="0.2s" (click)="onToogleClick(1)">WHY BARC?
                        </dt>
                        <dd class="fadeInUp" data-wow-delay="0.3s" *ngIf="tabToShow === 1">
                            <p>BARC is a quality oriented, Reliable testing laboratory with state of the art testing equipment
                                ,technology with data integrity. We follow stringent international and national quality protocols to ensure
                                your tests are as accurate as possible.</p>
                        </dd>
                        <!-- Single FAQ Area -->
                        <dt class="wave fadeInUp" data-wow-delay="0.3s" (click)="onToogleClick(2)">HOW WILL I RECEIVE  REPORTS?</dt>
                        <dd *ngIf="tabToShow === 2">
                            <p>Test reports are generally emailed in PDF format, also we send with the hard copies of the test
                                reports for their records.</p>
                        </dd>
                        <!-- Single FAQ Area -->
                        <dt class="wave fadeInUp" data-wow-delay="0.4s" (click)="onToogleClick(3)">HOW SOON CAN I EXPECT TEST RESULTS?</dt>
                        <dd *ngIf="tabToShow === 3">
                            <p>BARC offers best turnaround times in the industry, based on your scope of work and quantity of
                                samples it varies.</p>
                        </dd>
                        <!-- Single FAQ Area -->
                        <dt class="wave fadeInUp" data-wow-delay="0.5s" (click)="onToogleClick(4)">DO YOU HAVE FACILITY FOR SAMPLE PICKUP?</dt>
                        <dd *ngIf="tabToShow === 4">
                            <p>We have a dedicated team for sample pick up and the entire process from sample collection to report
                                delivery are handled by our own staff to ensure uninterrupted service. we offer while doing it all at a
                                fraction of cost that are comparable to other labs.</p>
                        </dd>
                    </dl>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- ##### FAQ & Timeline Area End ##### -->

<!-- ##### blog Area Start ##### -->
<div class="blog section-padding-100-50" hidden>
    <div class="container">
        <div class="section-heading text-center">
            <span>Our Latest News</span>
            <h2 class="d-blue bold fadeInUp" data-wow-delay="0.3s">Our Blog Posts</h2>
            <p class="fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
        </div>
        <div class="row align-items-center">
            <!-- Single Blog Post -->
            <div class="col-12 col-md-4 col-sm-6">
                <div class="single-blog-area wow fadeInUp" data-wow-delay="0.2s">
                    <!-- Post Thumbnail -->
                    <div class="blog_thumbnail">
                        <img src="assets/img/services/blog1.jpg" alt="">
                    </div>
                    <!-- Post Content -->
                    <div class="blog-content">

                        <div class="post-meta">
                            <p>By <a href="#" class="post-author">Admin</a> <a href="#">Apr 10, 2020</a> <a href="#"
                                    class="post-comments">7 comments</a></p>
                        </div>
                        <a href="index-single-blog.html" class="post-title">
                            <h4>RESEARCH & VERIFY OF A PHYSICS LABORATORY</h4>
                        </a>
                        <p hidden>Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Accusamus fugiat at vitae,
                            ratione sapiente repellat.</p>
                        <a href="index-single-blog.html" class="btn dream-btn green-btn mt-0">Read Details</a>
                    </div>
                </div>
            </div>
            <!-- Single Blog Post -->
            <div class="col-12 col-md-4 col-sm-6">
                <div class="single-blog-area wow fadeInUp" data-wow-delay="0.4s">
                    <!-- Post Thumbnail -->
                    <div class="blog_thumbnail">
                        <img src="assets/img/services/blog2.jpg" alt="">
                    </div>
                    <!-- Post Content -->
                    <div class="blog-content">

                        <div class="post-meta">
                            <p>By <a href="#" class="post-author">Admin</a> <a href="#">Apr 10, 2020</a> <a href="#"
                                    class="post-comments">7 comments</a></p>
                        </div>
                        <a href="index-single-blog.html" class="post-title">
                            <h4>RESEARCH & VERIFY OF A PHYSICS LABORATORY</h4>
                        </a>
                        <p hidden>Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Accusamus fugiat at vitae,
                            ratione sapiente repellat.</p>
                        <a href="index-single-blog.html" class="btn dream-btn green-btn mt-0">Read Details</a>
                    </div>
                </div>
            </div>
            <!-- Single Blog Post -->
            <div class="col-12 col-md-4 col-sm-6">
                <div class="single-blog-area wow fadeInUp" data-wow-delay="0.6s">
                    <!-- Post Thumbnail -->
                    <div class="blog_thumbnail">
                        <img src="assets/img/services/blog3.jpg" alt="">
                    </div>
                    <!-- Post Content -->
                    <div class="blog-content">

                        <div class="post-meta">
                            <p>By <a href="#" class="post-author">Admin</a> <a href="#">Apr 10, 2020</a> <a href="#"
                                    class="post-comments">7 comments</a></p>
                        </div>
                        <a href="index-single-blog.html" class="post-title">
                            <h4>RESEARCH & VERIFY OF A PHYSICS LABORATORY</h4>
                        </a>
                        <p hidden>Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Accusamus fugiat at vitae,
                            ratione sapiente repellat.</p>
                        <a href="index-single-blog.html" class="btn dream-btn green-btn mt-0">Read Details</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- ##### Blog Area End ##### -->