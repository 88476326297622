<div class="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
    <div class="divimage dzsparallaxer--target"
        style="width: 101%; height: 130%; background-image: url(assets/img/bg-img/bg-2.jpg)"></div>
    <!-- breadcrumb content -->
    <div class="breadcumb-content">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12">
                    <nav aria-label="breadcrumb" class="breadcumb--con text-center">
                        <h2 class="w-text title wow fadeInUp" data-wow-delay="0.2s">Contact Us</h2>
                        <ol class="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section-padding-100 contact_us_area" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-heading text-center">
                    <!-- Dream Dots -->
                    <span>Book Appointment Now!</span>
                    <h2 class="wow fadeInUp d-blue" data-wow-delay="0.3s">Contact With Us</h2>
                    <p class="wow fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur
                        adipiscing
                        elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
                </div>
            </div>
        </div>

        <!-- Contact Form -->
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <div class="contact_form">
                    <form action="/action_page.php" (ngSubmit)="onSendMessage(contactForm.value);contactForm.reset();"
                        #contactForm="ngForm">
                        <div class="row">
                            <div class="col-12">
                                <div id="success_fail_info"></div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="group wow fadeInUp" data-wow-delay="0.2s">
                                    <input class="form-control" type="text" [(ngModel)]="contactModel.name" name="name"
                                        id="name" required>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Name*</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="group wow fadeInUp" data-wow-delay="0.3s">
                                    <input type="text" [(ngModel)]="contactModel.email" name="email" id="email" email
                                        required #email="ngModel">
                                    <span class="help-bpx" *ngIf="email.touched && !email.valid">Please enter your valid
                                        email</span>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Email*</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="group wow fadeInUp" data-wow-delay="0.4s">
                                    <input type="text" [(ngModel)]="contactModel.subject" name="subject" id="subject"
                                        required>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Subject*</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="group wow fadeInUp" data-wow-delay="0.5s">
                                    <textarea name="message" [(ngModel)]="contactModel.message" id="message"></textarea>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Message</label>
                                </div>
                            </div>
                            <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.6s">
                                <button type="submit" class="btn dream-btn">Send
                                    Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>