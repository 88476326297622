import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

declare function handlePreloader(): any;
declare function showLoader(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public isHomePage: boolean = false;
  public activePage!: string;

  constructor(private _router: Router) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        showLoader();
        if (event.url.includes('/')) {
          this.activePage = event.url.replace('/', '');
        }
      }
    });
  }

  ngOnInit(): void {
    handlePreloader();
  }
}
