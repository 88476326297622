
<section class="main-slider revolution-slider">
    <div class="tp-banner-container service-pad" style="left: 0%;">
        <div class="tp-banner">
            <ul>
                <li data-transition="fade" data-slotamount="1" data-masterspeed="2000">
                    <img src="assets/img/bg-img/HOME-BANNER.png" alt="" data-bgposition="center top" data-bgfit="cover"
                        data-bgrepeat="no-repeat">
                </li>

                <li data-transition="slidedown" data-slotamount="1" data-masterspeed="2000">
                    <img src="assets/img/bg-img/FOOD.png" alt="" data-bgposition="center top" data-bgfit="cover"
                        data-bgrepeat="no-repeat">
                </li>

                <li data-transition="slidedown" data-slotamount="1" data-masterspeed="2000">
                    <img src="assets/img/bg-img/PHARMACEUTICALS-AND-DRUGS.png" alt="" data-bgposition="center top"
                        data-bgfit="cover" data-bgrepeat="no-repeat">
                </li>

                <li data-transition="slidedown" data-slotamount="1" data-masterspeed="2000">
                    <img src="assets/img/bg-img/ENVIRONMENTAL.png" alt="" data-bgposition="center top"
                        data-bgfit="cover" data-bgrepeat="no-repeat">
                </li>

                <li data-transition="slidedown" data-slotamount="1" data-masterspeed="2000">
                    <img src="assets/img/bg-img/WATER.png" alt="" data-bgposition="center top" data-bgfit="cover"
                        data-bgrepeat="no-repeat">
                </li>

                <li data-transition="slidedown" data-slotamount="1" data-masterspeed="2000">
                    <img src="assets/img/bg-img/SOFTLINE.png" alt="" data-bgposition="center top" data-bgfit="cover"
                        data-bgrepeat="no-repeat">
                </li>

                <li data-transition="slidedown" data-slotamount="1" data-masterspeed="2000">
                    <img src="assets/img/bg-img/MICROBIOLOGY.png" alt="" data-bgposition="center top" data-bgfit="cover"
                        data-bgrepeat="no-repeat">
                </li>
            </ul>
        </div>
    </div>

    <!-- Hero Content -->
    <!-- <div class="hero-section-content">
        <div class="container">
            <div class="row ">
                <div class="col-12 col-lg-6 col-md-12">
                    <div class="welcome-content text-left">
                        <h1 class="bold wow fadeInUp d-blue" data-wow-delay="0.2s">We Are Much More A <span
                                class="b-blue">Testing Laboratory</span></h1>
                        <p class="wow fadeInUp b-text" data-wow-delay="0.3s">BARC is a full-service ISO9001-2015,
                             FDA, NABL accredited testing laboratory that offers advanced quality control and development services 
                             for a wide range of industries. Our services will improve the sensitivity, accuracy, and speed of your 
                             analytical routine laboratory testing, and stay a step ahead of the challenges of this complex market.</p>

                        <div class="dream-btn-group fadeInUp" data-wow-delay="0.4s">
                            <a href="#" class="btn dream-btn mr-3">get started</a>
                            <a [routerLink]="['/contact']" class="btn dream-btn head-btn-m">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>

<section class="our_services_area section-padding-0-0 relative hex-pat-1 service-pad" id="services">
    <div class="container">

        <div class="section-heading text-center">
            <span>Our Services</span>
            <h2 class="d-blue bold fadeInUp" data-wow-delay="0.3s">Our Core Services</h2>
            <p class="fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
        </div>



        <div class="row">

            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <a [routerLink]="['/service/food']">
                    <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.4s">

                        <div class="service_img">
                            <img src="assets/img/services/Food.png" alt="">
                        </div>
                        <div class="serv_icon">
                            <img src="assets/img/icons/s4.png" alt="">
                        </div>
                        <div class="service-content">
                            <h6 class="d-blue bold">Food</h6>
                            <p>The quality of food plays a crucial role which is directly concerned with every
                                individual. </p>
                                <p hidden>The food quality gets affected by the internal factors like microbial,
                                chemical, physical and external factors like shape, taste, texture, colour, size.</p>
                               <p hidden> which must be appropriate to get appreciated in terms of health safety issues.</p>
                                <p hidden>BARC is fully furnished with all the essential equipment’s which are required
                                for complete testing of broad range of food products (raw and processed
                                both).We also provide services required for new food products in research and
                                development fields.</p>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <a [routerLink]="['/service/pharmaceuticals']">
                    <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.4s">

                        <div class="service_img">
                            <img src="assets/img/services/Pharmaceuticals.png" alt="">
                        </div>
                        <div class="serv_icon">
                            <img src="assets/img/icons/s6.png" alt="">
                        </div>
                        <div class="service-content">
                            <h6 class="d-blue bold">Pharmaceuticals</h6>
                            <p>BARC is well-versed in the complexities of modern pharmaceutical laboratory
                                testing. </p>
                                <p hidden>It is one of the most demanding fields of analytical chemistry,
                                occupying the intersection between biochemical, synthetic, and analytical
                                chemistry.</p>
                                <p hidden> WE provide unmatched expertise in the field of pharmaceutical lab
                                testing. Our analysts are regularly tasked with detecting, quantifying, and
                                identifying contaminants in dosage form pharmaceuticals.</p>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <a [routerLink]="['/service/environmental']">
                    <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.2s">

                        <div class="service_img">
                            <img src="assets/img/services/Environmental.png" alt="">
                        </div>
                        <div class="serv_icon">
                            <img src="assets/img/icons/s2.png" alt="">
                        </div>
                        <div class="service-content">
                            <h6 class="d-blue bold">Environmental</h6>
                            <p>With the continuous decrease in quality of environment, it has become
                                essential to keep on monitoring.</p>
                                <p hidden> with various
                                regulations set by the regulatory bodies.</p>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <a [routerLink]="['/service/water']">
                    <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.2s">

                        <div class="service_img">
                            <img src="assets/img/services/Water.png" alt="">
                        </div>
                        <div class="serv_icon">
                            <img src="assets/img/icons/s1.png" alt="">
                        </div>
                        <div class="service-content">
                            
                            <h6 class="d-blue bold">Water</h6>
                            
                            <p>"Water is a synonym for ‘life’ as no life can 
                                    exist on earth without it."</p>
                            <p>Water is a synonym for ‘life’...</p>       
                            <p hidden> as no life can 
                                exist on earth without it. BARC understands the importance of purity of water for human consumption and industrial works.</p>
                                <p hidden> We offer competitive facilities for testing of drinking water and waste water.</p>
                            <p hidden>We own a well-equipped laboratory which possess the most advanced and modernized instruments required for carrying out a complete analysis for water samples used in batteries , petroleum refineries, fermentation industries , food processing industries , cooling towers etc.</p>
                            <p hidden> The test protocols are strictly in accordance with the specified standards mention by the concerned authorities.</p>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <a [routerLink]="['/service/microbiology']">
                    <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.2s">

                        <div class="service_img">
                            <img src="assets/img/services/Microbiology.png" alt="">
                        </div>
                        <div class="serv_icon">
                            <img src="assets/img/icons/s3.png" alt="">
                        </div>
                        <div class="service-content">
                            <h6 class="d-blue bold">Microbiology</h6>
                            <p>BARC has been providing world class services to various industries for
                                Microbiology testing for past few years.</p>
                            <p hidden>We ensure that the products are
                                safe and are manufactured as per the requirements of specific
                                standards.</p>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <a [routerLink]="['/service/softline']">
                    <div class="service_single_content v2 text-center wow fadeInUp" data-wow-delay="0.4s">

                        <div class="service_img">
                            <img src="assets/img/services/Softlines.png" alt="">
                        </div>
                        <div class="serv_icon">
                            <img src="assets/img/icons/s5.png" alt="">
                        </div>
                        <div class="service-content">
                            <h6 class="d-blue bold">Softlines</h6>
                            <p>Before a soft line material or a product is put into market for consumer
                                application...</p>
                                <p hidden> it needs to be checked on different stages of its production.
                                 and
                                practice under various conditions.</p>
                            <p hidden>With reference to every growing consumer demands and complying with the
                                stringent government rules and regulations, BARC helps your products satisfy
                                the entire requirement and helps maintain their quality and performance and
                                minimize the risk associated with the material and secure the interest of
                                consumer and manufacturer as well.</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<div class="clearfix"></div>

<section class="section-padding-100-70 relative map-before">

    <div class="container">

        <div class="section-heading text-center">
            <h2 class="wow fadeInUp d-blue bold" data-wow-delay="0.3s">Accreditation</h2>
            <p class="wow fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur adipiscing
                elit.
                Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <div class="service_single_content transparent text-center wow fadeInUp" data-wow-delay="0.2s">
                    <!-- Icon -->
                    <div class="how_icon">
                        <img style="height: 200px !important;" src="assets/img/icons/NABL-Official-LOGO.png"
                            class="colored-icon" alt="">
                    </div>
                    <h6>NABL</h6>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam</p> -->
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <div class="service_single_content transparent text-center wow wow fadeInUp" data-wow-delay="0.3s">
                    <!-- Icon -->
                    <div class="how_icon">
                        <img style="height: 200px !important;" src="assets/img/icons/cdsco-FDA-INDIA.jpg"
                            class="colored-icon" alt="">
                    </div>
                    <h6>FDA</h6>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam</p> -->
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Content -->
                <div class="service_single_content transparent text-center wow fadeInUp">
                    <!-- Icon -->
                    <div class="how_icon">
                        <img style="height: 200px !important;" src="assets/img/icons/ISO-CERTIFICATION.png"
                            class="colored-icon" alt="">
                    </div>
                    <h6>ISO 9001-2015</h6>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam</p> -->
                </div>
            </div>

        </div>
    </div>
</section>

<!-- ##### About Us Area Start ##### -->
<section class="about-us-area section-padding-0-70">

    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-3 col-md-12 ">

                <div class="col-xs-12">
                    <div class="article special box-shadow">
                        <img style="height: 200px !important;" src="assets/img/icons/ICP-OES.jpg" class="mb-10" alt="">
                        <h3 class="article__title">ICP OES</h3>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="article special box-shadow">
                        <img style="height: 200px !important;" src="assets/img/icons/ics-6000.jpg" class="mb-10" alt="">
                        <h3 class="article__title">ICS 6000</h3>
                    </div>
                </div>

            </div>

            <div class="col-12 col-lg-3 col-md-12">

                <div class="col-xs-12">
                    <div class="article special box-shadow mts-50">
                        <img style="height: 200px !important;" src="assets/img/icons/FAT-EXTRACTION.jpg" class="mb-10"
                            alt="">
                        <h3 class="article__title">Fat Extraction</h3>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="article special box-shadow">
                        <img style="height: 200px !important;" src="assets/img/icons/FTIR.jpg" class="mb-10" alt="">
                        <h3 class="article__title">FTIR</h3>
                    </div>
                </div>

            </div>

            <div class="col-12 col-lg-3 col-md-12 ">

                <div class="col-xs-12">
                    <div class="article special box-shadow">
                        <img style="height: 200px !important;" src="assets/img/icons/GC-HEAD SPACE.jpg" class="mb-10"
                            alt="">
                        <h3 class="article__title">GC-HEAD SPACE</h3>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="article special box-shadow">
                        <img style="height: 200px !important;" src="assets/img/icons/lcmsms.webp" class="mb-10" alt="">
                        <h3 class="article__title">lcmsms</h3>
                    </div>
                </div>

            </div>

            <div class="col-12 col-lg-3 col-md-12">

                <div class="col-xs-12">
                    <div class="article special box-shadow mts-50">
                        <img style="height: 200px !important;" src="assets/img/icons/TSQ-8000 gcmsms.jpg" class="mb-10"
                            alt="">
                        <h3 class="article__title">TSQ-8000 gcmsms</h3>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="article special box-shadow">
                        <img style="height: 200px !important;" src="assets/img/icons/vanquish-core-HPLC.jpg"
                            class="mb-10" alt="">
                        <h3 class="article__title">Vanquish Core HPLC</h3>
                    </div>
                </div>

            </div>

            <div class="col-12 col-lg-6 offset-lg-0 col-md-10 offset-md-1" hidden>
                <div class="who-we-contant mt-s">
                    <div class="promo-section">
                        <h3 class="special-head ">Learn More About Us!</h3>
                    </div>
                    <h4 class="bl-text fadeInUp" data-wow-delay="0.3s">We Are The Trusted Experts We Keep Things
                        Simple</h4>
                    <p class="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Duis at dictum risus, non suscipit arcu. Quisque aliquam posuere tortor, sit amet
                        convallis nunc scelerisque in.</p>
                    <p class="fadeInUp" data-wow-delay="0.5s">Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Nemo quo laboriosam, dolorum ducimus aliquam consequuntur!</p>
                    <a class="btn dream-btn green-btn mt-30 fadeInUp" data-wow-delay="0.6s">Read More</a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- ##### About Us Area End ##### -->

<!-- ##### FAQ & Timeline Area Start ##### -->
<div class="faq-timeline-area section-padding-100-85" id="faq">
    <div class="container">
        <div class="section-heading text-center">
            <span>Important questions</span>
            <h2 class="d-blue bold fadeInUp" data-wow-delay="0.3s"> Frequently Questions</h2>
            <p class="fadeInUp" data-wow-delay="0.4s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                <div class="welcome-meter about-sec-wrapper wow fadeInUp" data-wow-delay="0.4s">
                    <img src="assets/img/core-img/about-sec2.jpg" class="about-i" alt="">
                    <div class="article special width-50 box-shadow">
                        <div class="form-head">
                            <div class="form-head-icon"><img src="assets/img/icons/info1.png" alt=""></div>
                            <div class="form-head-info">
                                <h6 class="w-text">9:00 a.m to 6:00 p.m.(CST)</h6>
                                <p class="g-text">Monday through Friday</p>
                            </div>
                        </div>
                        <div class="form-head">
                            <div class="form-head-icon"><img src="assets/img/icons/info2.png" alt=""></div>
                            <div class="form-head-info">
                                <h6 class="w-text">enquiry@barcindia.com</h6>
                                <p class="g-text">Monday through Friday</p>
                            </div>
                        </div>
                        <div class="form-head mb-0">
                            <div class="form-head-icon"><img src="assets/img/icons/info3.png" alt=""></div>
                            <div class="form-head-info">
                                <h6 class="w-text">080-29652966</h6>
                                <p class="g-text">9:00 a.m to 6:00 p.m</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-12">
                <div class="dream-faq-area mt-s ">
                     <dl style="margin-bottom:0">
                        <!-- Single FAQ Area -->
                        <dt class="wave fadeInUp" data-wow-delay="0.2s">WHY BARC?
                        </dt>
                        <dd class="fadeInUp" data-wow-delay="0.3s">
                            <p>BARC is a quality oriented, Reliable testing laboratory with state of the art testing
                                equipment
                                ,technology with data integrity. We follow stringent international and national quality
                                protocols to ensure
                                your tests are as accurate as possible.</p>
                        </dd>
                        <!-- Single FAQ Area -->
                        <dt class="wave fadeInUp" data-wow-delay="0.3s">HOW WILL I RECEIVE  REPORTS?</dt>
                        <dd>
                            <p>Test reports are generally emailed in PDF format, also we send with the hard copies of
                                the test
                                reports for their records.</p>
                        </dd>
                        <!-- Single FAQ Area -->
                        <dt class="wave fadeInUp" data-wow-delay="0.4s">HOW SOON CAN I EXPECT
                            TEST RESULTS?</dt>
                        <dd>
                            <p>BARC offers best turnaround times in the industry, based on your scope of work and
                                quantity of
                                samples it varies.</p>
                        </dd>
                        <!-- Single FAQ Area -->
                        <dt class="wave fadeInUp" data-wow-delay="0.5s">DO YOU HAVE FACILITY
                            FOR SAMPLE PICKUP?</dt>
                        <dd>
                            <p>We have a dedicated team for sample pick up and the entire process from sample collection
                                to report
                                delivery are handled by our own staff to ensure uninterrupted service. we offer while
                                doing it all at a
                                fraction of cost that are comparable to other labs.</p>
                        </dd>
                        <!-- Single FAQ Area -->
                    </dl>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- ##### FAQ & Timeline Area End ##### -->

<!-- ##### blog Area Start ##### -->
<div class="blog section-padding-100-50" hidden>
    <div class="container">
        <div class="section-heading text-center">
            <span>Our Latest News</span>
            <h2 class="d-blue bold fadeInUp" data-wow-delay="0.3s">Our Blog Posts</h2>
            <p class="fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
        </div>
        <div class="row align-items-center">
            <!-- Single Blog Post -->
            <div class="col-12 col-md-4 col-sm-6">
                <div class="single-blog-area wow fadeInUp" data-wow-delay="0.2s">
                    <!-- Post Thumbnail -->
                    <div class="blog_thumbnail">
                        <img src="assets/img/services/blog1.jpg" alt="">
                    </div>
                    <!-- Post Content -->
                    <div class="blog-content">

                        <div class="post-meta">
                            <p>By <a href="#" class="post-author">Admin</a> <a href="#">Apr 10, 2020</a> <a href="#"
                                    class="post-comments">7 comments</a></p>
                        </div>
                        <a href="index-single-blog.html" class="post-title">
                            <h4>RESEARCH & VERIFY OF A PHYSICS LABORATORY</h4>
                        </a>
                        <p hidden>Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Accusamus fugiat at vitae,
                            ratione sapiente repellat.</p>
                        <a href="" class="btn dream-btn green-btn mt-0">Read Details</a>
                    </div>
                </div>
            </div>
            <!-- Single Blog Post -->
            <div class="col-12 col-md-4 col-sm-6">
                <div class="single-blog-area wow fadeInUp" data-wow-delay="0.4s">
                    <!-- Post Thumbnail -->
                    <div class="blog_thumbnail">
                        <img src="assets/img/services/blog2.jpg" alt="">
                    </div>
                    <!-- Post Content -->
                    <div class="blog-content">

                        <div class="post-meta">
                            <p>By <a href="#" class="post-author">Admin</a> <a href="#">Apr 10, 2020</a> <a href="#"
                                    class="post-comments">7 comments</a></p>
                        </div>
                        <a href="index-single-blog.html" class="post-title">
                            <h4>RESEARCH & VERIFY OF A PHYSICS LABORATORY</h4>
                        </a>
                        <p hidden>Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Accusamus fugiat at vitae,
                            ratione sapiente repellat.</p>
                        <a href="" class="btn dream-btn green-btn mt-0">Read Details</a>
                    </div>
                </div>
            </div>
            <!-- Single Blog Post -->
            <div class="col-12 col-md-4 col-sm-6">
                <div class="single-blog-area wow fadeInUp" data-wow-delay="0.6s">
                    <!-- Post Thumbnail -->
                    <div class="blog_thumbnail">
                        <img src="assets/img/services/blog3.jpg" alt="">
                    </div>
                    <!-- Post Content -->
                    <div class="blog-content">

                        <div class="post-meta">
                            <p>By <a href="#" class="post-author">Admin</a> <a href="#">Apr 10, 2020</a> <a href="#"
                                    class="post-comments">7 comments</a></p>
                        </div>
                        <a href="index-single-blog.html" class="post-title">
                            <h4>RESEARCH & VERIFY OF A PHYSICS LABORATORY</h4>
                        </a>
                        <p hidden>Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Accusamus fugiat at vitae,
                            ratione sapiente repellat.</p>
                        <a href="" class="btn dream-btn green-btn mt-0">Read Details</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- ##### FAQ & Timeline Area End ##### -->

<!-- ##### pricing Area start ##### -->
<section class="pricing section-padding-100-70" style="background: #edf6fd" id="pricing" hidden>

    <div class="container">
        <div class="section-heading text-center">
            <span>Our Pricing</span>
            <h2 class="d-blue bold fadeInUp" data-wow-delay="0.3s">Our Pricing Plans</h2>
            <p class="fadeInUp" data-wow-delay="0.4s" hidden>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
        </div>
        <div class="row no-gutters">
            <!-- Single Table -->
            <div class="col-lg-4 col-md-6">
                <div class="single_price_table_content text-center wow fadeInUp" data-wow-delay="0.2s">
                    <div class="price_table_text">
                        <h5>Basic Blood Profile</h5>
                        <h1>$39.99</h1>
                        <p>Per Month</p>
                    </div>
                    <div class="table_text_details">
                        <p>Fasting Required</p>
                        <p>Report available</p>
                        <p>Unlimited Checkups </p>
                        <p>Receive Report by Email</p>
                        <p>Delivered Same Day</p>
                    </div>
                    <div class="table_btn mt-50">
                        <a href="#" class="btn dream-btn green-btn">Start</a>
                    </div>
                </div>
            </div>
            <!-- Single Table -->
            <div class="col-lg-4 col-md-6">
                <div class="single_price_table_content active text-center wow fadeIn" data-wow-delay="0.3s">
                    <div class="price_table_text">
                        <h5>Platinum Health Check</h5>
                        <h1>$69.99</h1>
                        <p>Per Month</p>
                    </div>
                    <div class="table_text_details">
                        <p>Fasting Required</p>
                        <p>Report available</p>
                        <p>Unlimited Checkups </p>
                        <p>Receive Report by Email</p>
                        <p>Delivered Same Day</p>
                    </div>
                    <div class="table_btn mt-50">
                        <a href="#" class="btn dream-btn green-btn">Start</a>
                    </div>
                </div>
            </div>
            <!-- Single Table -->
            <div class="col-lg-4 col-md-6">
                <div class="single_price_table_content text-center wow fadeInUp" data-wow-delay="0.4s">
                    <div class="price_table_text">
                        <h5>Gold Health Check</h5>
                        <h1>$84.99</h1>
                        <p>Per Month</p>
                    </div>
                    <div class="table_text_details">
                        <p>Fasting Required</p>
                        <p>Report available</p>
                        <p>Unlimited Checkups </p>
                        <p>Receive Report by Email</p>
                        <p>Delivered Same Day</p>
                    </div>
                    <div class="table_btn mt-50">
                        <a href="#" class="btn dream-btn green-btn">Start</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
<!-- ##### pricing Area End ##### -->